import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Gender, IContext } from "../../types/types";
import { useTranslation } from "react-i18next";
import { femaleGenderImages, roleImages, IAppImage, maleGenderImages } from "../../constants/raceImages";
import ProgressLoader from "../_components/ProgressLoader/ProgressLoader";
import { mapGender, mapRace } from "../../utils/transformation";
import { coinsToNextLevel } from "../../constants/coinsToNextLevel";
import { getNextLevelCoinsAmount } from "../../utils/money";
import { formatTimeHHMMSS } from "../../utils/time";
import { getFromStorage } from "../../utils/imageUtil";
import Balance from '../_components/Balance/Balance';
import SlideUp from "../_components/SlideUp/SlideUp";
import Settings from "../_components/Settings/Settings";
import DailyBonus from "../_components/DailyBonus/DailyBonus";
import ModalWindow from "../_components/ModalWindow/ModalWindow";
import Thief from "../_components/Thief/Thief";
import Fight from '../_components/Fight/Fight';

const Home: React.FC = () => {
  const maxLevel = coinsToNextLevel.length;
  const [t] = useTranslation();
  const {
    user, thief, greetingsShowed, setGreetingsShowed, setUser, coins, isMiningStarted, isMiningFinished, handleMining,
    dailyBonusModels, claimBonus,
    timeToUnlockBonusButton,
    setTimeToUnlockBonusButton
  } = useOutletContext<IContext>();
  const [mainCharacterImage, setMainCharacterImage] = useState<IAppImage | null>(null);
  const [mainCharacterIcon, setMainCharacterIcon] = useState<IAppImage | null>(null);
  const [secondsRemaining, setSecondsRemaining] = useState<number>(0);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [settingsShow, setSettingsShow] = useState<boolean>(false);
  const [bonusShow, setBonusShow] = useState<boolean>(false);
  const [warning, setWarnign] = useState<string>('');
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const [thiefShow, setThiefShow] = useState<boolean>(thief && !greetingsShowed && true)
  const [fightShow, setFightShow] = useState<boolean>(false)

  const [isHorizontal, setIsHorizontal] = useState(window.innerWidth > window.innerHeight);

  const handleResize = () => {
    setIsHorizontal(window.innerWidth > window.innerHeight);
  };

  useEffect(() => {
    setGreetingsShowed(true);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSettings = () => {
    setSettingsShow(!settingsShow);
  }

  const toggleThief = () => {
    setThiefShow(!thiefShow);
  }

  const toggleFight = () => {
    let shouldShowWarning = false;
    if (warning) {
      setShowWarning(true);
      shouldShowWarning = true;
    }
    if (!shouldShowWarning) {
      setFightShow(!fightShow);
    }
  }

  const toggleBonus = () => {
    setBonusShow(!bonusShow);
  }

  useEffect(() => {
    if (user.miningFinish) {
      const miningFinish = new Date(user.miningFinish).getTime() / 1000;
      const difference = miningFinish - Math.round(new Date().getTime() / 1000);
      if (!Number.isNaN(difference) && difference >= 0) {
        setSecondsRemaining(Math.round(difference));
      }
    } else {
      setSecondsRemaining(0)
    }
  }, [coins, isMiningStarted]);

  useEffect(() => {
    if (isMiningFinished === true) {
      setSecondsRemaining(0)
    }
  }, [isMiningFinished]);

  useEffect(() => {
    if (user && !initialized) {
      let mainCharacterImageObject: IAppImage | undefined;
      switch (mapGender(user.gender!.toUpperCase())) {
        case Gender.MALE: {
          mainCharacterImageObject = maleGenderImages.find((image) => image.value === user.race);
          break;
        }
        case Gender.FEMALE: {
          mainCharacterImageObject = femaleGenderImages.find((image) => image.value === user.race);
          break;
        }
        default:
          console.error(`User gender "${user.gender}" is not found`)
          break;
      }
      const roleImageObj: IAppImage | undefined = roleImages.find((image) => mapRace(image.value) === mapRace(user.race));

      setMainCharacterImage(mainCharacterImageObject ?? null);
      setMainCharacterIcon(roleImageObj ?? null);
    }
  }, [user, mainCharacterImage, mainCharacterIcon]);

  useEffect(() => {
    if (mainCharacterImage && mainCharacterIcon && isMiningStarted !== null) setInitialized(true);
  }, [mainCharacterImage, mainCharacterIcon, isMiningStarted]);

  if (!initialized) {
    return <ProgressLoader />
  }

  const neededCoins = getNextLevelCoinsAmount(user.level) - Math.round(Number(user.coins));

  return (
    <div
      className={`home h-dvh flex flex-col justify-items-stretch overflow-auto cover ${isHorizontal ? 'bg-contain' : 'bg-cover'}`}
      style={{ backgroundImage: `url(${getFromStorage(`bg-${user.race}-${isHorizontal ? 'h' : 'v'}.png`)})` }}
    >
      <div className="h-38 px-3 py-2 m-4 rounded-2xl bg-gray-primary shadow-gold">
        <div className={"flex"}>
          <div className={"flex flex-col justify-around"}>
            <div className={"relative w-16 h-16"}>
              <span className={"absolute block text-gold text-xl -bottom-2 right-0 font-semibold"}>{user.level}</span>
              <img
                className={"h-full w-full object-contain"}
                alt={mainCharacterIcon?.alt}
                src={mainCharacterIcon?.getImage()}
              />
            </div>
          </div>
          <div className={"flex flex-col w-full px-2"}>
            <div className={"flex justify-between"}>
              <div className={"flex flex-col"}>
                <h3 className="font-semibold">{t('app.home.miningPower', { value: Math.round(user.miningPower * 3600) })}</h3>
                {!Number.isNaN(neededCoins) && (
                  <div className="flex items-center flex-wrap">
                    <span>
                      {t('app.home.coinsNeeded')}
                    </span>
                    <div className="flex items-center">
                      <Balance
                        src={getFromStorage(`coin.png`)}
                        alt={'coins:'}
                        userCoins={neededCoins}
                        imgClass="h-4 w-4 ml-2 mr-1"
                        textClass={'leading-5 h-5'}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={"flex gap-2 items-center justify-between"}>
                {/*TODO add settings, fight, etc. buttons*/}
                {/*<button className={"bg-gray-600 w-8 rounded-md py-1 px-2"}>*/}
                {/*  1*/}
                {/*</button>*/}
                {/*<button className={"bg-gray-600 w-8 rounded-md py-1 px-2"}>*/}
                {/*  2*/}
                {/*</button>*/}
                {/*<button className={"bg-gray-600 w-8 rounded-md py-1 px-2"}>*/}
                {/*  3*/}
                {/*</button>*/}
              </div>
            </div>
            {!Number.isNaN(neededCoins) && (
              <>
                <div className="w-full rounded-full overflow-hidden h-2.5 my-2 border-2 border-grayPrimary">
                  <div
                    className="bg-gold h-2.5"
                    style={{ width: `${Number(user.coins) / getNextLevelCoinsAmount(user.level) * 100}%` }}
                  ></div>
                </div>
              </>
            )}
          </div>
          <div
            className="min-w-8 w-8 h-8 self-center"
            onClick={toggleSettings}
          >
            <img
              className={"w-full h-full"}
              src={getFromStorage('setting.png')}
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        className={'flex align-middle justify-center gap-2 pt-4 appearance-top'}
      >
        <Balance
          src={getFromStorage(`coin.png`)}
          alt={'coins:'}
          userCoins={user.coins}
          imgClass="h-8 w-8"
          textClass="block leading-8 text-3xl font-semibold text-center"
        />
      </div>
      <div
        className="main-hero flex flex-col justify-between grow pt-4 px-4 pb-24 z-20"
      >
        <div
          className={"flex flex-col mx-auto grow justify-center relative"}
        >
          <div
            className="bg-transparent"
            style={{ maxHeight: "40dvh" }}
          >
            <img
              className={"h-full mx-auto"}
              src={mainCharacterImage?.getImage(!Number.isNaN(user.level) && user.level <= maxLevel ? user.level : maxLevel)}
              alt={mainCharacterImage?.alt}
            />
          </div>
          {/*top-left*/}
          <div className={'absolute w-16 flex flex-col gap-1 left-2 top-2 items-center appearance-top'}>
            <div
              className={`block w-12`}
              onClick={toggleThief}
            >
              <img
                className={"h-full w-full mx-auto"}
                src={getFromStorage('theft.png')}
                alt=""
              />
            </div>
          </div>
          {/*bottom-right*/}
          <div className={'absolute w-16 flex flex-col gap-1 right-2 bottom-6 items-center appearance-bottom'}>
            <div
              className={`block w-12`}
              onClick={toggleFight}
            >
              <img
                className={"h-full w-full mx-auto"}
                src={getFromStorage('fight.png')}
                alt=""
              />
            </div>
          </div>
          {/*bottom-left*/}
          <div className={'absolute w-16 flex flex-col gap-1 left-2 bottom-6 items-center appearance-left'}>
            <div
              className={`block w-12 ${!timeToUnlockBonusButton && 'shake'}`}
              onClick={toggleBonus}
            >
              <img
                className={"h-full w-full mx-auto"}
                src={getFromStorage('bonus.png')}
                alt=""
              />
            </div>
            {timeToUnlockBonusButton && (
              <span className={'opacity-90 absolute -bottom-6'}>{timeToUnlockBonusButton}</span>
            )}
          </div>
        </div>
        <div className="mb-2 w-auto shadow-md overflow-hidden rounded-2xl appearance-bottom">
          {isMiningFinished !== null ?
            <button
              className={`bg-amber-900 py-3 px-6 w-full h-14 block relative overflow-hidden ${!!isMiningFinished && 'clickable'}`}
              disabled={!isMiningFinished}
              onClick={() => handleMining()}
            >
              {!!isMiningFinished && (
                <span
                  className={"text-xl font-semibold"}
                  hidden={!isMiningFinished}
                >
                  {t('app.home.claimMinedCoins', { value: Math.round(coins) })}
                </span>
              )}
              <div
                className="bg-gray-800 absolute h-full right-0 top-0 progress-bar"
                style={{ width: `${100 - (Number(user.miningTime) - secondsRemaining) / Number(user.miningTime) * 100}%` }}
              />
              <div
                className={`h-full w-full left-0 top-0 justify-center items-center absolute ${!!isMiningFinished ? 'hidden' : 'flex'}`}
              >
                <span className={"opacity-70 pl-2"}>
                  {t('app.home.coinsMined')}
                </span>
                <Balance
                  src={getFromStorage(`coin.png`)}
                  alt={'coins:'}
                  userCoins={coins}
                  imgClass="h-4 w-4 ml-2 mr-1"
                  textClass="text-xl font-semibold"
                />
                {secondsRemaining > 0 && (
                  <span className={"opacity-70 pl-2"}>
                    {formatTimeHHMMSS(secondsRemaining)}
                  </span>
                )}
              </div>
            </button>
            :
            <button
              className={'bg-green-950 py-3 px-6 w-full h-14 clickable'}
              onClick={() => handleMining()}
            >
              <span className={"text-xl font-semibold"}>
                {t('app.home.startCoinsMining')}
              </span>
            </button>
          }
        </div>
      </div>
      <SlideUp
        isVisible={settingsShow}
        onCloseButtonClick={toggleSettings}
      >
        <Settings />
      </SlideUp>
      <SlideUp
        isVisible={bonusShow}
        onCloseButtonClick={toggleBonus}
      >
        <DailyBonus
          dailyBonusModels={dailyBonusModels}
          userModel={user}
          claimBonus={claimBonus}
          timeToUnlockBonusButton={timeToUnlockBonusButton}
          setTimeToUnlockBonusButton={setTimeToUnlockBonusButton}
        />
      </SlideUp>
      <ModalWindow onCloseButtonClick={toggleThief} isShow={thiefShow} header={t('app.thief.')}>
        <Thief
          user={user}
          setUser={setUser}
          thief={thief}
        ></Thief>
      </ModalWindow>
      <ModalWindow onCloseButtonClick={toggleFight} isShow={fightShow} header={t('app.fight.')}>
        <Fight
          isFightModalOpen={fightShow}
          setIsFightModalOpen={setFightShow}
          warning={warning}
          setWarning={setWarnign}
          showWarning={showWarning}
          setShowWarning={setShowWarning}
        />
      </ModalWindow>
    </div>
  );
}

export default Home;
