import { ReactNode } from "react";

const FightWarningModal = ({children}: {children:ReactNode}) => {
	return (
		<div className="absolute w-full left-0 h-[70%] z-10 flex">
			<div className="bg-black bg-opacity-80 m-auto h-full w-[80%] border rounded-2xl p-5 text-center">
				{children}
			</div>
		</div>
	);
};

export default FightWarningModal;
