const getIndexColor = (index: number) => {
  switch (index) {
    case 1:{
      return 'text-gold';
    }
    case 2:{
      return 'text-zinc-400';
    }
    case 3:{
      return 'text-amber-700';
    }
    default: {
      return ''
    }
  }
}

export {getIndexColor};
