export const enemies = [
  [
    { id: 1, name: "goblin" },
    { id: 2, name: "prickly_hedgehog" },
    { id: 3, name: "forest_spider" },
    { id: 4, name: "lesser_forest_spirit" },
    { id: 5, name: "crab" },
    { id: 6, name: "forest_rodent" },
    { id: 7, name: "forest_snake" },
    { id: 8, name: "little_troll" },
    { id: 9, name: "dust_worm" },
    { id: 10, name: "spirit_trickster" },
  ],
  [
    { id: 1, name: "dark_wolf" },
    { id: 2, name: "skeleton_warrior" },
    { id: 3, name: "ghost_hunter" },
    { id: 4, name: "shadow_knight" },
    { id: 5, name: "ominous_sparrow" },
    { id: 6, name: "dusty_phantom" },
    { id: 7, name: "dark_bat" },
    { id: 8, name: "rattling_zombie" },
    { id: 9, name: "ominous_spider" },
    { id: 10, name: "evil_phantom" }
  ],
  [
    { id: 1, name: "cave_troll" },
    { id: 2, name: "mountain_scorpion" },
    { id: 3, name: "stone_snake" },
    { id: 4, name: "small_basilisk" },
    { id: 5, name: "noisy_goblin" },
    { id: 6, name: "stone_rat" },
    { id: 7, name: "dark_gargoyle" },
    { id: 8, name: "cave_crab" },
    { id: 9, name: "small_giant" },
    { id: 10, name: "iron_marten" }
  ],
  [
    { id: 1, name: "sand_worm" },
    { id: 2, name: "dust_golem" },
    { id: 3, name: "shape-shifting_snake" },
    { id: 4, name: "fire_scorpion" },
    { id: 5, name: "sand_ghost" },
    { id: 6, name: "stone_bison" },
    { id: 7, name: "fire_bat" },
    { id: 8, name: "storm" },
    { id: 9, name: "desert_minotaur" },
    { id: 10, name: "lord_of_chaos" }
  ],
  [
    { id: 1, name: "stone_cyclops" },
    { id: 2, name: "ice_troll" },
    { id: 3, name: "rock_drake" },
    { id: 4, name: "flying_hawk_guardian" },
    { id: 5, name: "giant_rat" },
    { id: 6, name: "mountain_snake" },
    { id: 7, name: "thunder_golem" },
    { id: 8, name: "frost_barbarian" },
    { id: 9, name: "lava_spider" },
    { id: 10, name: "ice_eagle" }
  ],
  [
    { id: 1, name: "forest_giant" },
    { id: 2, name: "forest_deer" },
    { id: 3, name: "poisonous_mandrake" },
    { id: 4, name: "sliding_fog" },
    { id: 5, name: "thicket_bear" },
    { id: 6, name: "ent_guardian" },
    { id: 7, name: "fire_phoenix" },
    { id: 8, name: "forest_snake" },
    { id: 9, name: "thorny_grapevine" },
    { id: 10, name: "forest_drake" }
  ],
  [
    { id: 1, name: "ghost_knight" },
    { id: 2, name: "dead_wizard" },
    { id: 3, name: "skeleton_archer" },
    { id: 4, name: "cursed_gargoyle" },
    { id: 5, name: "ghost_guardian" },
    { id: 6, name: "corrupt_priest" },
    { id: 7, name: "skeleton_warriors" },
    { id: 8, name: "ghost_hound" },
    { id: 9, name: "skeleton_sorcerer" },
    { id: 10, name: "cursed_hunter" }
  ],
  [
    { id: 1, name: "water_snake" },
    { id: 2, name: "deep_guardian" },
    { id: 3, name: "sea_scorpion" },
    { id: 4, name: "siren" },
    { id: 5, name: "crab_executioner" },
    { id: 6, name: "water_elemental" },
    { id: 7, name: "sea_harpooner" },
    { id: 8, name: "ghost_shark" },
    { id: 9, name: "coral_sorcerer" },
    { id: 10, name: "" }
  ],
  [
    { id: 1, name: "sand_giant" },
    { id: 2, name: "stone_spirit" },
    { id: 3, name: "wind_wolf" },
    { id: 4, name: "thunder_snake" },
    { id: 5, name: "dust_golem" },
    { id: 6, name: "flying_demon" },
    { id: 7, name: "fire_griffin" },
    { id: 8, name: "sand_drake" },
    { id: 9, name: "enchanted_warrior" },
    { id: 10, name: "stone_minotaur" }
  ],
  [
    { id: 1, name: "ice_golem" },
    { id: 2, name: "snow_spirit" },
    { id: 3, name: "frost_wolf" },
    { id: 4, name: "ice_harpy" },
    { id: 5, name: "ice_giant" },
    { id: 6, name: "polar_bear" },
    { id: 7, name: "ice_drake" },
    { id: 8, name: "frozen_wizard" },
    { id: 9, name: "snow_werwolf" },
    { id: 10, name: "frost_gargoyle" }
  ],
  [
    { id: 1, name: "black_demon" },
    { id: 2, name: "fiery_ghost" },
    { id: 3, name: "arachnoid_destroyer" },
    { id: 4, name: "undead_king" },
    { id: 5, name: "soul_devourer" },
    { id: 6, name: "lord_of_darkness" },
    { id: 7, name: "fire_elemental" },
    { id: 8, name: "bone_dragon" },
    { id: 9, name: "stone_colossus" },
    { id: 10, name: "lord_of_chaos" }
  ],
];
