import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { getFromStorage } from "../../../utils/imageUtil";
import { formatTimeHHMMSS } from "../../../utils/time";
import React from "react";

const FightStartScreen = ({
  bet,
  userCoins,
  miningPower,
  setBet,
  fightCooldownRemaining,
  loading,
  onStartClick,
}: {
  bet: number;
  userCoins: number;
  miningPower: number;
  setBet: (bet: number) => void;
  fightCooldownRemaining: number;
  loading: boolean;
  onStartClick: () => void;
}) => {

  const { t } = useTranslation();
  const calcRange = Math.round(((bet - 1) / ((miningPower * 3600 * 3) - 1)) * 100);

  const defaultBet = miningPower * 3600 * 3;
  const maxBet = userCoins >= defaultBet ? defaultBet : userCoins;

  return (
    <div className="text-center flex flex-col h-full p-2">
      <div className="text-center flex flex-col gap-4 h-full p-2">
        <div className={'flex justify-center w-full rounded-2xl overflow-hidden max-h-64'}>
          <img
            className={'object-fill h-full'}
            src={getFromStorage('fight_bg.jpg')}
            alt=""
          />
        </div>
        <p className="text-2xl">{t("app.fight.doYouWantStart")}</p>
        <div className="w-[90%] mx-auto flex flex-col gap-2">
          <label htmlFor="bet">{parse(t("app.fight.yourBetIs", { bet: `<span class="text-gold text-xl ">${bet}</span>` }).toString())}</label>
          <div className="w-full relative flex my-4">
            <input
              className="range-slider"
              type="range"
              id="bet"
              min="1"
              max={maxBet}
              value={bet}
              onChange={(e) => setBet(Number(e.target.value))}
            />
            <div
              className="progress h-[8px] w-full absolute pointer-events-none -translate-x-full rounded-[4px]"
              style={{
                width: `${calcRange}%`,
                left: `${calcRange}%`,
                backgroundColor: "#052e16",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className={'grow'}></div>
      <div className={'w-full min-h-12 content-center shadow-md overflow-hidden appearance-bottom'}>
        {
          loading ?
            <button className={"bg-graySecondary rounded-2xl py-3 px-6 w-full h-12 overflow-hidden"} />
            :
            fightCooldownRemaining > 0 ?
              (
                <button className={"bg-graySecondary rounded-2xl py-3 px-6 w-full h-12 overflow-hidden"}>
                  {formatTimeHHMMSS(fightCooldownRemaining)}
                </button>
              )
              :
              (
                <button
                  className={`w-full rounded-2xl  bg-amber-900 py-3 px-6 h-12 block relative overflow-hidden clickable font-semibold text-xl`}
                  onClick={onStartClick}
                >
                  <span>{t("app.fight.startGame")}</span>
                </button>
              )
        }
      </div>
    </div>
  );
};

export default FightStartScreen;
