import { IThiefModel } from "../../../types/types";
import { roleImages } from "../../../constants/raceImages";
import { mapRace } from "../../../utils/transformation";
import { getFromStorage } from "../../../utils/imageUtil";
import Balance from "../Balance/Balance";
import React from "react";
import { useTranslation } from "react-i18next";
import { getIndexColor } from "../../../utils/statsListItemUtil";

interface ThiefListItemProps {
  thiefModel: IThiefModel;
  index?: number;
  userId?: number;
}

const ThiefListItem = ({ thiefModel, index, userId }: ThiefListItemProps) => {
  const [t] = useTranslation();

  return (
    <li
      className={`bg-gray-primary rounded-2xl ${thiefModel.id === userId && 'border border-gold'}`}
      key={thiefModel.id}
    >
      <div className={"flex items-center p-2"}>
        {
          index &&
          <div className={'w-8 h-8 text-center text-xl font-bold leading-8'}>
            <span className={getIndexColor(index)}>{index}</span>
          </div>
        }
        <div className={"mr-4 relative"}>
          <img
            src={thiefModel.photoUrl ?? roleImages.find(i => mapRace(i.value) === mapRace(thiefModel.race ?? ""))?.getImage() ?? getFromStorage(`coin.png`)}
            className={`w-12 h-12 object-contain ${thiefModel.photoUrl ?? 'rounded-full overflow-hidden'}`}
            alt="avatar"
          />
          <div className={"absolute block text-xl text-gold text-center -bottom-2 right-0 font-bold rounded-full "}>
            <span>{thiefModel.level}</span>
          </div>
        </div>
        <div className={"flex flex-col justify-center"}>
          <p>{thiefModel.id === userId ? 'You' : thiefModel.username ?? `${thiefModel.firstName ?? t('app.stats.fallBackName')} ${thiefModel.lastName ?? ""}`}</p>
          <div className={"flex items-center"}>
            <span>
              {t('app.stats.stolen')}
            </span>
            <Balance
              src={getFromStorage(`coin.png`)}
              alt={'coins:'}
              userCoins={thiefModel.totalStolenCoins}
              imgClass="h-4 w-4 ml-2 mr-1"
            />
          </div>
        </div>
      </div>
    </li>
  );
}

export default ThiefListItem;
