import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enemies } from "../../../utils/enemies";


interface IFightCharacter  {
  level: number,
  alt?: string,
  image?: string,
  hp: number,
  hpLeft: number,
  enemy?: string
}

const FightCharacter = ({level, alt, image, hp, hpLeft, enemy}: IFightCharacter) => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState();
  const importImagesForLevel = async (level: number, enemyId: string) => {
    try {
      console.log(enemyId)
      const enemy = enemies[level - 1].find(e => e.id === Number(enemyId) + 1)
      console.log(enemy)
      const image = await import(`../../../assets/images/battle/lvl${level}/${enemy?.name}.png`);
      setImageSrc(image.default || image)
    } catch (error) {
      console.error("Error loading images:", error);
    }
  };

  useEffect(() => {
    if (enemy)
      importImagesForLevel(level, enemy);
  }, [enemy, level])

	return (
    <div className={`relative flex flex-col bg-graySecondary p-2 rounded-xl shadow-goldSecondary`}>
      {enemy
        ? <img
            className={"h-[100px] w-[80px] object-cover rounded-xl object-top"}
            src={imageSrc}
            alt="enemy"
          />
        : <img
            className={"h-[100px] w-[80px] object-contain rounded-xl object-center"}
            alt={t(`${alt? alt : 'icon_of_character'}`)}
            src={image}
          />
      }
      <div
        className={
          "mt-2 w-full h-4 relative bg-[#800020] rounded-full overflow-hidden align-self-end flex items-center justify-center shadow-green"
        }
      >
        <span className="z-10">
          {hpLeft}/{hp}
        </span>
        <div
          className={`h-full bg-[#228B22] absolute left-0`}
          style={{ width: `${(hpLeft / hp) * 100}%` }}
        ></div>
      </div>
    </div>
	);
};

export default FightCharacter;
